import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GoogleTagManagerService } from '@wienerberger/services';
import { CookiesState } from '@wienerberger/states';
import { AppState } from './app.state';
import { IUser } from './services/models/user';
import { skip } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NetworkService } from './services/network.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const ID_THANKS_SCRIPT = 'idThanksScript';

@Component({
  selector: 'wb-root',
  template: ` <router-outlet></router-outlet> `,
})
@UntilDestroy({ checkProperties: true })
export class AppComponent implements OnInit {
  private _browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  private _isThanksScriptLoaded = false;

  constructor(
    private readonly _router: Router,
    private readonly _store: Store,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _toastrService: ToastrService,
    private readonly _networkService: NetworkService,
  ) {}

  private static _isThankYouPage(url: string): boolean {
    return url.includes('thanks-for-registration');
  }

  public ngOnInit(): void {
    this._setRouteSubscription();
    this._setOnlineOfflineChangesSubscription();
  }

  private _setOnlineOfflineChangesSubscription(): void {
    this._networkService.onlineChanges$.pipe(untilDestroyed(this), skip(1)).subscribe((online) => {
      if (online) {
        this._toastrService.info('Odzyskano połączenie internetowe.');
      } else {
        this._toastrService.warning('Utracono połączenie internetowe.');
      }
    });
  }

  private _removeThankYouScriptToDom(): void {
    if (!this._isThanksScriptLoaded) {
      return;
    }
    const doc = this._browserGlobals.documentRef();
    doc.getElementById(ID_THANKS_SCRIPT)?.remove();
  }

  private _addThankYouScriptToDom(): void {
    const doc = this._browserGlobals.documentRef();
    const gtmScript = doc.createElement('script');
    gtmScript.id = ID_THANKS_SCRIPT;
    gtmScript.src = '//gde-default.hit.gemius.pl/gdejs/xgde.js';
    doc.head.insertBefore(gtmScript, doc.head.firstChild);
    this._isThanksScriptLoaded = true;
    this._pushOnDataLayer();
  }

  private _setRouteSubscription(): void {
    this._router.events.pipe(untilDestroyed(this)).subscribe((routeEvent) => {
      const isCookiesAllowed: boolean = this._store.selectSnapshot(CookiesState.cookiesAllowed);
      const user: IUser = this._store.selectSnapshot(AppState?.user);
      const isAllowedToPush = isCookiesAllowed || isCookiesAllowed === undefined;
      if (routeEvent instanceof NavigationEnd && isAllowedToPush) {
        const url = routeEvent.url;
        const gtmTag = {
          event: 'page',
          pageName: url,
          userId: user?.id,
        };
        this._googleTagManagerService.pushTag(gtmTag);
        this._handleThankYouScript(url);
      }
    });
  }

  private _handleThankYouScript(url: string): void {
    if (AppComponent._isThankYouPage(url)) {
      this._addThankYouScriptToDom();
    } else {
      this._removeThankYouScriptToDom();
    }
  }

  private _pushOnDataLayer(): void {
    const windowRef = this._browserGlobals.windowRef();
    (windowRef._gdeaq = windowRef._gdeaq || []).push(['nc', 'false']);
    windowRef._gdeaq.push(['cmp', 'false'], ['gdpr_consent', '']);
    windowRef._gdeaq.push([
      'pageView',
      'gde-default',
      'nGfg7UiEPzAuLsUda2amDscQ78YwbRsWoA7lXu3UJMf.l7',
      'my.adocean.pl',
      'cw0AYLqTNvqR9Z1ZCJpS7cuzvWID9IdsA5yiJc0xZMn.c7',
    ]);
  }
}
