<ng-template #defaultTitle>
  <h2>Sprawdź <span class="accent">gdzie kupisz</span> produkty</h2>
</ng-template>

<div [ngClass]="{ 'mt-0': isSelectMode }" class="wb-where-to-buy">
  <div *ngIf="!isSelectMode" class="container">
    <div class="row">
      <div class="col-12">
        <div class="wb-where-to-buy__description">
          <ng-container *ngTemplateOutlet="title || defaultTitle"></ng-container>
        </div>
        <div class="wb-where-to-buy__label">
          <label class="ms-3 me-3">
            <input
              (click)="$event.stopPropagation()"
              (keyup)="keyUp.next($event.target['value'])"
              autocomplete="off"
              class="wb-where-to-buy--search-text"
              type="text"
            />
          </label>
          <app-search-result-list
            (locationSelected)="onLocationSelected($event)"
            [resultsOfSearch]="searchResults$ | async"
          />
        </div>
        <button (click)="onSearch($event)" class="black-button wb-where-to-buy--search-button" type="button">
          SZUKAJ
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="promoName === null" class="container wb-form">
    <div class="wb-where-to-buy__checkboxes pt-3">
      <div *ngIf="visibleCheckboxes.porothermVisible">
        <input (change)="onChange()" [(ngModel)]="porothermChecked" id="porotherm" type="checkbox" />
        <label class="wb-where-lab" for="porotherm">
          <img src="assets/images/logo-porotherm.png" alt="porotherm" loading="lazy" />
        </label>
      </div>
      <div *ngIf="visibleCheckboxes.koramicVisible">
        <input (change)="onChange()" [(ngModel)]="koramicChecked" id="koramic" type="checkbox" />
        <label class="wb-where-lab" for="koramic">
          <img src="assets/images/logo-koramic.png" alt="koramic" loading="lazy" />
        </label>
      </div>
      <div *ngIf="visibleCheckboxes.semmelrockVisible">
        <input (change)="onChange()" [(ngModel)]="semmelrockChecked" id="semmelrock" type="checkbox" />
        <label class="wb-where-lab" for="semmelrock">
          <img src="assets/images/logo-semmelrock.png" alt="semmelrock" loading="lazy" />
        </label>
      </div>
    </div>
  </div>
  <div class="container pb-4 pb-lg-5">
    <p class="mb-0">
      W Promocji biorą udział <strong class="accent">tylko wskazane na mapie punkty sprzedaży</strong>.
    </p>
  </div>
  <div *ngIf="promoName != null" class="container wb-form list-title pt-3 pb-3 pb-lg-4">
    <p>Lista dystrybutorów {{ promoName }}</p>
  </div>
  <div class="row">
    <div class="col-12">
      <div
        (leafletMapReady)="onMapReady($event)"
        (leafletMarkerClusterReady)="markerClusterReady($event)"
        *ngIf="isSelectMode"
        [appLeafletMarkerCluster]="markerClusterData"
        [leafletBaseLayers]="baseLayers"
        [leafletLayersControlOptions]="layersControlOptions"
        [leafletMarkerClusterOptions]="markerClusterOptions"
        [leafletOptions]="options"
        leaflet
        style="height: 600px"
      ></div>
      <div
        (leafletMapReady)="onMapReady($event)"
        (leafletMarkerClusterReady)="markerClusterReady($event)"
        *ngIf="!isSelectMode"
        [appLeafletMarkerCluster]="markerClusterData"
        [leafletBaseLayers]="baseLayers"
        [leafletLayersControlOptions]="layersControlOptions"
        [leafletMarkerClusterOptions]="markerClusterOptions"
        [leafletOptions]="options"
        leaflet
        style="height: 350px"
      ></div>
    </div>
  </div>

  <ngx-spinner
    [bdColor]="'rgba(51,51,51,0.2)'"
    [color]="'#fff'"
    [fullScreen]="false"
    [name]="SPINNER_NAME"
    [size]="'medium'"
    [type]="'ball-scale-multiple'"
  >
  </ngx-spinner>
</div>
