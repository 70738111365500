import { Directive, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive()
export abstract class BasicComponentAbstract implements OnDestroy {
  public subscriptionsForDestroy = new Subscription();
  protected readonly _destroy$ = new Subject<void>();

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.subscriptionsForDestroy.unsubscribe();
  }
}
