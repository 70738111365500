import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
})
export class FaqComponent {
  constructor(private readonly _renderer2: Renderer2) {}

  public change(el): void {
    const currentValue = (el as HTMLElement).attributes.getNamedItem('aria-expanded').value;
    this._renderer2.setAttribute(el, 'aria-expanded', currentValue === 'true' ? 'false' : 'true');
  }
}
