import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { ILoginDto } from './models/login-dto';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private readonly _httpClient: HttpClient) {}

  /**
   * Check username and password. Return user jwt token.
   * @param loginDto
   * @param recaptchaKey
   */
  public login(loginDto: ILoginDto, recaptchaKey: string): Observable<string> {
    return this._httpClient
      .post<any>(`${environment.api}/login/check-user`, loginDto, {
        params: {
          recaptchaKey,
        },
      })
      .pipe(
        map(({ token }: any) => {
          return token;
        }),
      );
  }
}
