import { Inject, Injectable, InjectionToken } from '@angular/core';

export const GOOGLE_TAG_MANAGER_ID = new InjectionToken<string>('googleTagManagerId');

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  public readonly ID_GTM_SCRIPT = 'GTMscript';
  public readonly ID_GTM_IFRAME = 'GTMiframe';

  private isLoaded = false;
  private readonly gtmId: string;

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    }
  };

  constructor(
    @Inject(GOOGLE_TAG_MANAGER_ID)
    public googleTagManagerId: string
  ) {
    this.gtmId = googleTagManagerId;
  }

  public getDataLayer(): any {
    this._createDataLayer();
    return window['dataLayer'];
  }

  public addGtmToDom(): void {
    this._createDataLayer();
    const doc = this.browserGlobals.documentRef();
    this._pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    const gtmScript = doc.createElement('script');
    gtmScript.id = this.ID_GTM_SCRIPT;
    // gtmScript.async = true;
    gtmScript.src = '//www.googletagmanager.com/gtm.js?id=' + this.gtmId;
    doc.head.insertBefore(gtmScript, doc.head.firstChild);

    const ifrm = doc.createElement('iframe');
    ifrm.setAttribute('src', '//www.googletagmanager.com/ns.html?id=' + this.gtmId);
    ifrm.style.width = '0';
    ifrm.style.height = '0';
    ifrm.style.display = 'none';
    ifrm.style.visibility = 'hidden';

    const noscript = doc.createElement('noscript');
    noscript.id = this.ID_GTM_IFRAME;
    noscript.appendChild(ifrm);

    doc.body.insertBefore(noscript, doc.body.firstChild);
    this.isLoaded = true;
  }

  public removeGtmFromDom(): void {
    const doc = this.browserGlobals.documentRef();
    doc.getElementById(this.ID_GTM_IFRAME).remove();
    doc.getElementById(this.ID_GTM_SCRIPT).remove();
  }

  public pushTag(item: object) {
    if (!this.isLoaded) {
      this.addGtmToDom();
    }
    this._pushOnDataLayer(item);
  }

  private _createDataLayer(): void {
    const window = this.browserGlobals.windowRef();
    window['dataLayer'] = window['dataLayer'] || [];
  }

  private _pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

}
