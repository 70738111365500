import { FileTypeResult, fromBuffer } from 'file-type/browser';

export async function hasCorrectRealMimeType(
  file: File,
  allowedMimeTypes: string[],
  allowedExtension: string,
): Promise<{
  correctRealMimeType: boolean;
  type: FileTypeResult;
}> {
  const buffer = await file.arrayBuffer();
  const type: FileTypeResult = await fromBuffer(buffer);
  return {
    correctRealMimeType: allowedMimeTypes.includes(type.mime) && allowedExtension.includes(type.ext),
    type,
  };
}
