import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-validate-backend-message',
  template: `
    <span *ngIf="control.hasError('backend')">
         <small class="accent" *ngFor="let error of control.errors['backend'] | keyvalue">
             <br>{{ error.value }}
        </small>
    </span>
  `
})
export class ValidateBackendMessageComponent {

  private _control: AbstractControl;

  get control(): AbstractControl {
    return this._control;
  }

  @Input()
  set control(control: AbstractControl) {
    this._control = control;
  }

}

@NgModule({
  declarations: [ValidateBackendMessageComponent],
  imports: [
    CommonModule
  ],
  exports: [ValidateBackendMessageComponent]
})
export class ValidateBackendMessageModule {
}
