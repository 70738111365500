import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { GoogleTagManagerService } from '@wienerberger/services';
import { CookiesState, CookiesStateAllow, CookiesStateSaveSettings } from '@wienerberger/states';
import { Observable, Subscription } from 'rxjs';
import { CookiesSettingsComponent } from './cookies-settings/cookies-settings.component';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesComponent implements OnInit, OnDestroy {
  @Select(CookiesState.cookiesAllowed) public cookiesAllowed$: Observable<boolean>;
  @Select(CookiesState.isCookiesInfoVisible) public isCookiesInfoVisible$: Observable<boolean>;
  private _isSettingOpen = false;
  private _actionsSuccessSubscription: Subscription;

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _ngbModal: NgbModal,
  ) {}

  private _cookiesLocalStorageKey: string;

  get cookiesLocalStorageKey(): string {
    return this._cookiesLocalStorageKey;
  }

  @Input()
  set cookiesLocalStorageKey(value: string) {
    this._cookiesLocalStorageKey = value;
  }

  public checkRequiredFields() {
    if (this.cookiesLocalStorageKey === null) {
      throw new Error("Attribute 'cookiesLocalStorageKey' is required");
    }
  }

  public onAcceptCookies(): void {
    this._store.dispatch(new CookiesStateAllow(this.cookiesLocalStorageKey));
  }

  public onShowCookiesSettings(): boolean {
    if (this._isSettingOpen) {
      return;
    }
    this._isSettingOpen = true;
    this._openModalCookiesSettingsWindow()
      .then(() => {
        this._isSettingOpen = false;
      })
      .catch(() => {
        this._isSettingOpen = false;
      });

    return false;
  }

  public ngOnInit(): void {
    this._actionsSuccessSubscription = this._actions$
      .pipe(ofActionSuccessful(CookiesStateAllow, CookiesStateSaveSettings))
      .subscribe((action) => {
        if (action instanceof CookiesStateAllow) {
          this._googleTagManagerService.addGtmToDom();
        } else if (action instanceof CookiesStateSaveSettings) {
          this._removeGtmFromDomIfNotAllowed();
        }
      });
    this.checkRequiredFields();
  }

  public ngOnDestroy(): void {
    this._actionsSuccessSubscription.unsubscribe();
  }

  private _removeGtmFromDomIfNotAllowed(): void {
    const cookiesAllowed = this._store.selectSnapshot(CookiesState.cookiesAllowed);
    if (!cookiesAllowed) {
      this._googleTagManagerService.removeGtmFromDom();
    }
  }

  private _openModalCookiesSettingsWindow(): Promise<any> {
    const modalWindowOptions: NgbModalOptions = {
      centered: true,
      backdrop: true,
      keyboard: true,
      size: 'lg',
      windowClass: 'body',
    };
    const modalRef = this._ngbModal.open(CookiesSettingsComponent, modalWindowOptions);
    modalRef.componentInstance.cookiesLocalStorageKey = this.cookiesLocalStorageKey;
    return modalRef.result;
  }
}

@NgModule({
  declarations: [CookiesComponent],
  imports: [CommonModule],
  exports: [CookiesComponent],
})
export class CookiesModule {}
