import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { AppState } from '../app.state';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly _jwtHelperService: JwtHelperService;

  constructor(
    private readonly _store: Store,
    private readonly _toastrService: ToastrService,
  ) {
    this._jwtHelperService = new JwtHelperService();
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._store.selectSnapshot(AppState.getToken);
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(req).pipe(
      catchError((err) => {
        const statusTimeout = [504, 408, 0];
        if (statusTimeout.includes(err.status)) {
          this._toastrService.warning('Sieć internetowa, z której korzystasz jest zbyt słaba.');
        }
        return throwError(err);
      }),
    );
  }
}
