import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FormStateAbstract, FormStateModel } from '@wienerberger/data';
import { Utils } from '@wienerberger/utils';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, finalize, tap, timeoutWith } from 'rxjs/operators';
import { AppStateSetJwtToken, AppStateSetUserData } from '../../../../../../app.state';
import { IUser } from '../../../../../../services/models/user';
import { UserService } from '../../../../../../services/user.service';
import { LoginService } from './login.service';

export class LoginStateInit {
  public static readonly type = '[LoginState] init';
}

export class LoginStateLogin {
  public static readonly type = '[LoginState] login ';
}

// tslint:disable-next-line:no-empty-interface
export interface LoginStateModel extends FormStateModel {}

@State<LoginStateModel>({
  name: 'login',
  defaults: {
    formGroup: null,
    loading: false,
    form: null,
  },
})
@Injectable()
export class LoginState extends FormStateAbstract {
  public constructor(
    private readonly _loginService: LoginService,
    private readonly _userService: UserService,
    private readonly _recaptchaV3Service: ReCaptchaV3Service,
    protected readonly _toastrService: ToastrService,
  ) {
    super(_toastrService, 'login.form');
  }

  @Selector()
  public static getFormGroup({ formGroup }: LoginStateModel): UntypedFormGroup {
    return formGroup;
  }

  @Selector()
  public static getLoading({ loading }: LoginStateModel): boolean {
    return loading;
  }

  private static _buildFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
    });
  }

  @Action(LoginStateInit)
  public init({ patchState }: StateContext<LoginStateModel>): void {
    patchState({ formGroup: LoginState._buildFormGroup() });
  }

  @Action(LoginStateLogin)
  public login(state: StateContext<LoginStateModel>): Observable<any> {
    const { dispatch, getState, patchState } = state;
    const {
      formGroup,
      form: { status, model },
    } = getState();
    Utils.markFormGroupTouched(formGroup);
    if (status === 'INVALID') {
      this.showInvalidInfo();
      return of(null);
    }
    patchState({ loading: true });
    return this._recaptchaV3Service.execute('importantAction').pipe(
      catchError((error: any) => {
        patchState({ loading: false });
        return throwError(error);
      }),
      timeoutWith(5000, throwError('timeout')),
      concatMap((token: string) =>
        this._loginService.login(model, token).pipe(
          catchError((error: any) => {
            patchState({ loading: false });
            return throwError(error);
          }),
          concatMap((jwtToken: string) => {
            if (!jwtToken) {
              return of(null);
            }
            dispatch(new AppStateSetJwtToken(jwtToken));
            return this._userService.getMe().pipe(
              tap({
                next: (user: IUser) => {
                  formGroup.reset();
                  dispatch([new AppStateSetUserData(user), new Navigate([''])]);
                },
              }),
              finalize(() => {
                patchState({ loading: false });
              }),
            );
          }),
        ),
      ),
    );
  }
}
