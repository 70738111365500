import { UpdateFormDirty } from '@ngxs/form-plugin';
import { StateContext } from '@ngxs/store';
import { Utils } from '@wienerberger/utils';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { BasicStateAbstract } from './basic-state.abstract';
import { FormStateModel } from './form-state.model';

export abstract class FormStateAbstract extends BasicStateAbstract {
  protected constructor(protected readonly _toastrService: ToastrService, protected readonly _pathToForm: string) {
    super();
  }

  protected submitForm(state: StateContext<FormStateModel>, sendRequest: () => Observable<any>): Observable<any> {
    const { getState, dispatch } = state;
    const { formGroup } = getState();
    if (formGroup) {
      Utils.markFormGroupTouched(formGroup);
    }
    dispatch(
      new UpdateFormDirty({
        path: this._pathToForm,
        dirty: true,
      }),
    );
    const { form, loading } = getState();
    const { status } = form;
    if (status === 'INVALID') {
      this.showInvalidInfo();
      return of(null);
    } else if (loading) {
      return of(null);
    }
    return sendRequest();
  }

  protected showInvalidInfo(): void {
    this._toastrService.error('Prosimy wypełnić wszystkie wymagane pola.', 'Błąd');
  }
}
