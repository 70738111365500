import { CommonModule } from '@angular/common';
import { Component, DoCheck, Input, NgModule, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Utils } from '@wienerberger/utils';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-validate-message',
  template: `
    <div *ngIf="control.touched">
      <small
        class="absolute"
        [ngClass]="{ above: above }"
        *ngIf="errorMessage$ | async as errorMessage"
      >
        {{ errorMessage }}
      </small>
    </div>
  `,
})
export class ValidateMessageComponent implements OnDestroy, DoCheck {
  private _errorMsg = new BehaviorSubject<string>("");
  public errorMessage$ = this._errorMsg.asObservable();
  private _subscription: Subscription;
  private _touched: boolean;

  private _control: AbstractControl;
  get control(): AbstractControl {
    return this._control;
  }

  @Input()
  set control(control: AbstractControl) {
    this._control = control;
    this._setValueChangeSubscription();
  }

  private _above = false;

  get above(): boolean {
    return this._above;
  }

  @Input()
  set above(value: boolean) {
    this._above = value;
  }

  public ngDoCheck(): void {
    if (this._touched !== this.control?.touched) {
      this._touched = this.control.touched;
      this._setErrorMessage();
    } else if (this.control?.errors?.minlength) {
      this._setErrorMessage();
    }
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _setValueChangeSubscription(): void {
    this._subscription = this.control.valueChanges
      .pipe(debounceTime(25), distinctUntilChanged())
      .subscribe(() => {
        this._setErrorMessage();
      });
  }

  private _setErrorMessage(): void {
    let exists = false;
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) {
        exists = true;
        this._errorMsg.next(
          Utils.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName]
          )
        );
      }
    }
    if (!exists) {
      this._errorMsg.next("");
    }
  }
}

@NgModule({
  declarations: [ValidateMessageComponent],
  imports: [CommonModule],
  exports: [ValidateMessageComponent],
})
export class ValidateMessageModule {}
