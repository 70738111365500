import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AppState } from '../app.state';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class GuardService {
  public loader$ = new Subject<boolean>();
  public loader = false;
  private _jwtHelperService: JwtHelperService;

  constructor(
    private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly _store: Store,
  ) {
    this._jwtHelperService = new JwtHelperService();
    this.loader$.pipe(debounceTime(300)).subscribe((loader) => {
      this.loader = loader;
    });
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._canActivate(route, state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._canActivate(route, state);
  }

  private _canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.loader$.next(true);
    const { token, user } = this._store.selectSnapshot(AppState);

    if (!token || !user) {
      return this._goToRegisterPage(state);
    }

    const isTokenExpired = this._jwtHelperService.isTokenExpired(token);
    if (isTokenExpired) {
      return this._goToLoginPage(state);
    }
    return of(true);
  }

  private _goToRegisterPage(_: RouterStateSnapshot): Observable<boolean> {
    this.loader$.next(false);
    return of(false).pipe(
      tap(() => {
        this._router.navigate(['auth/register']).then();
      }),
    );
  }

  private _goToLoginPage(state: RouterStateSnapshot): Observable<boolean> {
    this.loader$.next(false);
    return of(false).pipe(
      tap(() => {
        const { url } = state;
        const containNotFoundPage = url.includes('404');
        const isEmpty = url?.trim()?.replace('/', '').length === 0;
        const isValidUrl = !isEmpty && !containNotFoundPage;
        this._router.navigate(['auth/login'], isValidUrl ? { queryParams: { returnUrl: url } } : {}).then();
      }),
    );
  }
}
