import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://6064a0d968854d74a271f589e20f4385@sentry.h2h.tech/5',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['https://wienerberger.h2h.tech', 'https://www.korzyscimurowane.pl'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
  debug: false,
  autoSessionTracking: true,
  normalizeDepth: 7,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .catch((err) => console.error(err));
