import { ChangeDetectorRef, inject } from '@angular/core';
import { BasicComponentAbstract } from './basic-component.abstract';

export abstract class BasicPopupComponentAbstract extends BasicComponentAbstract {
  public isPopupVisible = false;
  public popupAnimation: 'visible' | 'hide' = 'hide';

  protected readonly _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  protected constructor() {
    super();
  }

  public onClose(): void {
    this.popupAnimation = 'hide';
    setTimeout(() => {
      this.isPopupVisible = false;
      this._changeDetectorRef.detectChanges();
    }, 200);
    this._changeDetectorRef.detectChanges();
  }

  protected _showPopup(): void {
    this.isPopupVisible = true;
    this.popupAnimation = 'visible';
    this._changeDetectorRef.detectChanges();
  }
}
