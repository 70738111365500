import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';

export class CookiesStateAllow {
  public static readonly type = '[CookiesState] allow';

  constructor(
    public readonly cookiesLocalStorageKey: string
  ) {
  }
}

export class CookiesStateSaveSettings {
  public static readonly type = '[CookiesState] save settings';

  constructor(
    public readonly cookiesAllowed: boolean,
    public readonly cookiesLocalStorageKey: string
  ) {
  }
}

export interface CookiesStateModel {
  cookiesAllowed: boolean;
  isCookiesInfoVisible: boolean;
}

@State<CookiesStateModel>({
  name: 'cookies',
  defaults: {
    cookiesAllowed: undefined,
    isCookiesInfoVisible: false
  }
})
@Injectable()
export class CookiesState implements NgxsOnInit {

  @Selector()
  public static cookiesAllowed({ cookiesAllowed }: CookiesStateModel): boolean {
    return cookiesAllowed;
  }

  @Selector()
  public static isCookiesInfoVisible({ isCookiesInfoVisible }: CookiesStateModel): boolean {
    return isCookiesInfoVisible;
  }

  @Action(CookiesStateAllow)
  public init({ patchState }: StateContext<CookiesStateModel>, { cookiesLocalStorageKey }: CookiesStateAllow): void {
    localStorage.setItem(cookiesLocalStorageKey, 'true');
    patchState({ cookiesAllowed: true, isCookiesInfoVisible: false });
  }

  @Action(CookiesStateSaveSettings)
  public saveSettings({ patchState }: StateContext<CookiesStateModel>, { cookiesAllowed, cookiesLocalStorageKey }: CookiesStateSaveSettings): Observable<CookiesStateModel> {
    localStorage.setItem(cookiesLocalStorageKey, cookiesAllowed ? 'true' : 'false');
    return of(patchState({ cookiesAllowed, isCookiesInfoVisible: false }));
  }

  public ngxsOnInit({ patchState, getState }: StateContext<CookiesStateModel>): any | void {
    const { cookiesAllowed } = getState();
    if (cookiesAllowed === undefined) {
      patchState({ isCookiesInfoVisible: true });
    }
  }

}
