import { UntypedFormControl } from '@angular/forms';
import { ValidationResult } from '@wienerberger/utils';


export class PasswordValidator {

  public static special(control: UntypedFormControl): ValidationResult {
    const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};'`:"\\|,.<>\/?]/.test(control.value);
    if (!hasSpecial) {
      return {
        hasSpecial: true
      };
    }
    return null;
  }

  public static number(control: UntypedFormControl): ValidationResult {
    const hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      return {
        hasNumber: true
      };
    }
    return null;
  }

  public static upper(control: UntypedFormControl): ValidationResult {
    const hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      return {
        hasUpper: true
      };
    }
    return null;
  }

  public static lower(control: UntypedFormControl): ValidationResult {
    const hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      return {
        hasLower: true
      };
    }
    return null;
  }
}
