import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { LeafletDirective, LeafletDirectiveWrapper } from '@asymmetrik/ngx-leaflet';
import 'leaflet.markercluster';
import * as L from 'leaflet';

@Directive({
  selector: '[appLeafletMarkerCluster]'
})
export class LeafletMarkerClusterDirective implements OnChanges, OnInit {

  public leafletDirective: LeafletDirectiveWrapper;
  public markerClusterGroup: L.MarkerClusterGroup;

  // Hexbin data binding
  @Input()
  public appLeafletMarkerCluster: L.Layer[] = [];
  // Options binding
  @Input()
  public leafletMarkerClusterOptions: L.MarkerClusterGroupOptions;
  // Fired when the marker cluster is created
  @Output()
  public leafletMarkerClusterReady: EventEmitter<L.MarkerClusterGroup> = new EventEmitter<L.MarkerClusterGroup>();


  constructor(leafletDirective: LeafletDirective) {
    this.leafletDirective = new LeafletDirectiveWrapper(leafletDirective);
  }

  public ngOnInit() {
    this.leafletDirective.init();

    const map = this.leafletDirective.getMap();
    this.markerClusterGroup = L.markerClusterGroup(this.leafletMarkerClusterOptions);

    // Add the marker cluster group to the map
    this.markerClusterGroup.addTo(map);

    // Set the data now that the markerClusterGroup exists
    this.setData(this.appLeafletMarkerCluster);

    // Fire the ready event
    this.leafletMarkerClusterReady.emit(this.markerClusterGroup);
  }

  public ngOnChanges(changes: { [key: string]: SimpleChange }) {
    // Set the new data
    if (changes['markerData']) {
      this.setData(this.appLeafletMarkerCluster);
    }
  }

  private setData(layers: L.Layer[]): void {
    // Ignore until the markerClusterGroup exists
    if (null != this.markerClusterGroup) {
      this.markerClusterGroup.clearLayers();
      this.markerClusterGroup.addLayers(layers);
    }
  }

}
