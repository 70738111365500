import { FileItem, FileUploader } from 'ng2-file-upload';
import { ApplicationStatusType } from './application';
import { CertApplicationStatusType } from './cert-application';

export class PromotionUtils {

  public static getStatusNameForAdmin(status: ApplicationStatusType): string {
    switch (status) {
      case 'promotion_activated':
        return 'PROMOCJA AKTYWOWANA';
      case 'incomplete':
        return 'DO UZUPEŁNIENIA H2H';
      case 'document_uploaded':
        return 'DOKUMENTY WGRANE';
      case 'verified':
        return 'WERYFIKACJA WSTĘPNA POPRAWNA';
      case 'rejected':
        return 'WNIOSEK ODRZUCONY';
      case 'verified_by_wien':
        return 'WERYFIKACJA FORMALNA POPRAWNA';
      case 'payment':
        return 'WNIOSEK DO PŁATNOŚCI';
      case 'to_clarify':
        return 'WNIOSEK DO WYJAŚNIENIA';
      case 'sr_verification':
        return 'WYSŁANO DO WERYFIKACJI BUDOWY';
      case 'sr_verified':
        return 'WNIOSEK ZWERYFIKOWANY PRZEZ PRZEDSTAWICIELA HANDLOWEGO';
      case 'closed':
        return 'WNIOSEK ZAMKNIĘTY';
      default:
        return 'STATUS NOT RECOGNIZED';
    }
  }

  public static getCertStatusNameForAdmin(status: CertApplicationStatusType): string {
    switch (status) {
      case 'document_uploaded':
        return 'DOKUMENTY WGRANE';
      case 'incomplete':
        return 'DO UZUPEŁNIENIA H2H';
      case 'verified':
        return 'WERYFIKACJA WSTĘPNA POPRAWNA';
      case 'rejected':
        return 'WNIOSEK ODRZUCONY';
      case 'verified_by_wien':
        return 'WERYFIKACJA FORMALNA POPRAWNA';
      case 'payment':
        return 'WNIOSEK DO PŁATNOŚCI';
      case 'to_clarify':
        return 'WNIOSEK DO WYJAŚNIENIA';
      case 'sr_verification':
        return 'WYSŁANO DO WERYFIKACJI BUDOWY';
      case 'sr_verified':
        return 'WNIOSEK ZWERYFIKOWANY PRZEZ PRZEDSTAWICIELA HANDLOWEGO';
      case 'closed':
        return 'WNIOSEK ZAMKNIĘTY';
      default:
        return 'STATUS NOT RECOGNIZED';
    }
  }


  public static getStatusNameForCertClient(status: CertApplicationStatusType): string {
    switch (status) {
      case 'document_uploaded':
      case 'verified':
      case 'verified_by_wien':
      case 'to_clarify':
      case 'sr_verification':
      case 'sr_verified':
      case 'incomplete':
        return 'DOKUMENTY WGRANE';
      case 'payment':
        return 'ZWERYFIKOWANO DOKUMENTY';
      case 'rejected':
        return 'WNIOSEK ODRZUCONY';
      case 'closed':
        return 'WNIOSEK ZAMKNIĘTY';
      default:
        return 'STATUS NOT RECOGNIZED';
    }
  }

  public static getStatusNameForClient(status: ApplicationStatusType): string {
    switch (status) {
      case 'promotion_activated':
        return 'PROMOCJA AKTYWOWANA';
      case 'document_uploaded':
      case 'verified':
      case 'incomplete':
        return 'DOKUMENTY WGRANE';
      case 'rejected':
        return 'WNIOSEK ODRZUCONY';
      case 'verified_by_wien':
        return 'WERYFIKACJA FORMALNA POPRAWNA';
      case 'payment':
        return 'WNIOSEK DO PŁATNOŚCI';
      case 'to_clarify':
        return 'WNIOSEK DO WYJAŚNIENIA';
      case 'sr_verification':
        return 'WYSŁANO DO WERYFIKACJI BUDOWY';
      case 'sr_verified':
        return 'WNIOSEK ZWERYFIKOWANY PRZEZ PRZEDSTAWICIELA HANDLOWEGO';
      case 'closed':
        return 'WNIOSEK ZAMKNIĘTY';
      default:
        return 'STATUS NOT RECOGNIZED';
    }
  }


  public static async getFilesFromUploader<T>(uploaderFileProject: FileUploader, type: T): Promise<{
    type: T,
    file: {
      name: string,
      content: string
    }
  }[]> {
    if (uploaderFileProject.queue.length === 0) {
      return new Promise(resolve => {
        resolve([]);
      });
    }
    const files: {
      type: T,
      file: any
    }[] = [];
    for (const fileItem of uploaderFileProject.queue) {
      const file = await PromotionUtils.handleAfterAddingFile(fileItem);
      files.push({ type, file });
    }
    return new Promise(resolve => {
      resolve(files);
    });
  }

  public static async getFilesBinaryFromUploader<T>(uploaderFileProject: FileUploader, type: T): Promise<{
    type: T,
    file: File
  }[]> {
    if (uploaderFileProject.queue.length === 0) {
      return new Promise(resolve => {
        resolve([]);
      });
    }
    const files: {
      type: T,
      file: any
    }[] = [];
    for (const fileItem of uploaderFileProject.queue) {
      files.push({ type, file : fileItem._file});
    }
    return new Promise(resolve => {
      resolve(files);
    });
  }


  public static handleAfterAddingFile(item: FileItem): Promise<{
    name: string,
    content: string
  }> {
    const reader = new FileReader();
    reader.readAsDataURL(item._file);
    return new Promise((resolve) => {
      reader.onload = (event) => {
        resolve({
          name: item._file.name,
          content: event.target.result.toString().split(',')[1]
        });
      };
    });
  }

}
