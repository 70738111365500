import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BasicComponentAbstract } from './basic-component.abstract';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Directive()
@UntilDestroy({ checkProperties: true })
export abstract class SpinnerComponentAbstract extends BasicComponentAbstract implements OnInit, OnDestroy {
  public readonly SPINNER_NAME;
  public heightOfFreeSpace: number;

  protected constructor(
    protected readonly ngxSpinnerService: NgxSpinnerService,
    protected readonly spinnerName: string,
  ) {
    super();
    this.SPINNER_NAME = spinnerName;
  }

  public ngOnInit(): void {
    this.getLoadingObservable()
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((isLoading) => {
        isLoading ? this.ngxSpinnerService.show(this.SPINNER_NAME) : this.ngxSpinnerService.hide(this.SPINNER_NAME);

        if (isLoading === false) {
          setTimeout(() => {
            this.ngxSpinnerService.hide(this.SPINNER_NAME).then();
          }, 30000);
        }
      });
  }

  protected abstract getLoadingObservable(): Observable<boolean>;

  protected _setHeightOfFreeSpace(): void {
    const headerHeight = 132;
    const footerHeight = 99;
    this.heightOfFreeSpace = window.innerHeight - headerHeight - footerHeight;
  }
}
