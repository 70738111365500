<div *ngIf="isCookiesInfoVisible$ | async" class="cookies">
  <div class="container">
    <div class="cookies__alert row">
      <div class="col-md-9">
        <p>
          „My (Wienerberger Ceramika Budowlana Sp. z o.o.) wykorzystujemy na tej stronie cookies. Możesz wyrazić zgodę
          na wszystkie cookies
          <a style="color: #007bff" (click)="onShowCookiesSettings()"><strong>lub wybrać, które cookies</strong></a>
          (inne niż niezbędne) możemy wykorzystywać. Z uwagi na naszych dostawców (np. Google) pewne informacje mogą być
          przesyłane do państw poza EOG, które nie zapewniają odpowiedniego poziomu ochrony danych (np. USA). Jeżeli
          dalej korzystasz ze strony lub akceptujesz cookies jest to jednocześnie Twoja zgoda na transfer danych poza
          EOG. Przysługuje Ci prawo do wycofania tej zgody w każdym momencie (bez wpływu na poprawność przetwarzania
          przed jej wycofaniem). Więcej informacji w
          <a href="/assets/files/polityka_cookies.pdf" tabindex="1" target="_blank">
            <strong>„Polityce cookies”.</strong>
          </a>
        </p>
      </div>
      <div class="col-md-3">
        <button
          (click)="onAcceptCookies()"
          class="btn transparent-button float-end agree-btn"
          tabindex="3"
          title="WYRAŻAM ZGODĘ"
        >
          WYRAŻAM ZGODĘ
        </button>
      </div>
    </div>
  </div>
</div>
