import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { IListResponseData, IPos, IPosListDto } from '@wienerberger/data';
import { CookiesStateSaveSettings } from '@wienerberger/states';
import { Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { COOKIES_KEY } from './app.consts';
import { IUser } from './services/models/user';
import { UserService } from './services/user.service';

export class AppStateSetJwtToken {
  public static readonly type = '[AppState] set jwt token';

  constructor(public readonly jwtToken: string) {}
}

export class AppStateGetPositions {
  public static readonly type = '[AppState] get positions';

  constructor(
    public readonly payload: {
      porothermChecked: boolean;
      koramicChecked: boolean;
      semmelrockChecked: boolean;
    },
  ) {}
}

export class AppStateLogout {
  public static readonly type = '[AppState] logout';
}

export class AppStateSetUserData {
  public static readonly type = '[AppState] set user data';

  constructor(public readonly user: any) {}
}

export interface AppStateModel {
  loading: boolean;
  token: string;
  user: IUser;
  positions: IListResponseData<IPos>;
  porotherm: boolean;
  koramic: boolean;
  semmelrock: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: false,
    token: '',
    user: null,
    positions: null,
    porotherm: false,
    koramic: false,
    semmelrock: false,
  },
})
@Injectable()
export class AppState implements NgxsOnInit {
  constructor(private readonly _userService: UserService) {}

  @Selector()
  public static getToken({ token }: AppStateModel): string {
    return token;
  }

  @Selector()
  public static user({ user }: AppStateModel): IUser {
    return user;
  }

  @Selector()
  public static positions({ positions }: AppStateModel): IListResponseData<IPos> {
    return positions;
  }

  public ngxsOnInit(ctx?: StateContext<AppStateModel>): void {
    const cookiesAllowed = localStorage.getItem(COOKIES_KEY);
    if (cookiesAllowed === 'true' || cookiesAllowed === 'false') {
      ctx.dispatch(new CookiesStateSaveSettings(cookiesAllowed === 'true', COOKIES_KEY));
    }
  }

  @Action(AppStateSetJwtToken)
  public setJwtToken(
    { patchState }: StateContext<AppStateModel>,
    { jwtToken }: AppStateSetJwtToken,
  ): Observable<AppStateModel> {
    return of(patchState({ token: jwtToken }));
  }

  @Action(AppStateSetUserData)
  public setUserData(
    { patchState }: StateContext<AppStateModel>,
    { user }: AppStateSetUserData,
  ): Observable<AppStateModel> {
    return of(patchState({ user }));
  }

  @Action(AppStateLogout)
  public logout({ patchState, dispatch }: StateContext<AppStateModel>): Observable<any> {
    return this._userService.logout().pipe(
      concatMap((_) => dispatch(new Navigate(['auth/login']))),
      tap(() => {
        patchState({ user: null, token: null });
      }),
    );
  }

  @Action(AppStateGetPositions)
  public getPositions(
    { patchState, getState }: StateContext<AppStateModel>,
    { payload: { porothermChecked, koramicChecked, semmelrockChecked } }: AppStateGetPositions,
  ): Observable<IListResponseData<IPos>> {
    const { positions, semmelrock, porotherm, koramic } = getState();

    if (positions && semmelrock === semmelrockChecked && koramic === koramicChecked && porotherm === porothermChecked) {
      return of(positions);
    }
    patchState({ loading: true });
    const preparedObj = {
      page: 1,
      direction: 'desc',
      sort: 'name',
      perPage: 10000,
    };
    if (porothermChecked) {
      preparedObj['porotherm'] = '1';
    }
    if (koramicChecked) {
      preparedObj['koramic'] = '1';
    }
    if (semmelrockChecked) {
      preparedObj['semmelrock'] = '1';
    }
    return this._userService.getPositions(preparedObj as IPosListDto).pipe(
      tap((_positions) => {
        patchState({
          positions: _positions,
          koramic: koramicChecked,
          porotherm: porothermChecked,
          semmelrock: semmelrockChecked,
        });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
