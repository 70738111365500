import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPos } from '@wienerberger/data';
import * as L from 'leaflet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { INominatimResponse } from '../models/nominatim-response.model';

@Injectable({
  providedIn: 'root'
})
export class WhereToBuyService {

  constructor(
    private readonly _httpClient: HttpClient
  ) {
  }

  public static buildGroupMarker(cluster: L.MarkerCluster): L.DivIcon {
    const markers: L.Marker[] = cluster.getAllChildMarkers();
    return L.divIcon({
      html: `
        <figure>
          <img alt="marker-group" src="assets/images/marker-group.svg">
          <figcaption>
            <strong>${markers.length}</strong>
          </figcaption>
        </figure>
        `,
      className: 'wb-marker-group',
      iconSize: L.point(40, 40)
    });
  }

  public addressLookup(req?: any): Observable<INominatimResponse[]> {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${req}&viewbox=-25.0000%2C70.0000%2C50.0000%2C40.0000&bounded=1`;
    return this._httpClient
      .get(url)
      .pipe(
        map((data: any[]) =>
          data.map(({ lat, lon, display_name }: any) => ({
            displayName: display_name,
            latitude: lat,
            longitude: lon
          }))
        )
      );
  }

  public buildMapPopupContent(dealer: IPos, isSelectMode: boolean): string {
    let basicContent = `
    <strong>${dealer.name}</strong><br>
    <span>${dealer.zip_code}</span><br>
    <span>${dealer.city}</span><br>
    <span>${dealer.street}</span><br>
    `;
    if (dealer.website) {
      basicContent = basicContent + `<span>Www: <a target="_blank" href="http://${dealer.website}">${dealer.website}</a></span><br>`;
    }
    if (dealer.email) {
      basicContent = basicContent + `<span>E-mail: <a href="mailto: ${dealer.email}">${dealer.email}</a></span><br>`;
    }
    if (dealer.phone) {
      basicContent = basicContent + `<span>Telefon: <a href="tel:: ${dealer.phone}">${dealer.phone}</a></span>`;
    }
    if (isSelectMode === true) {
      return basicContent.concat(
        `<div class="row">
            <div class="col-12 mt-3 text-center">
              <button id="wb-button" (click)="confirmLocation()" class="transparent-button">ZATWIERDŹ</button>
            </div>
         </div>
        `);
    }
    return basicContent;
  }

  public setOnclickOnButtonWhenIsSelectMode(
    isSelectMode: boolean,
    callback: Function
  ): void {
    if (!isSelectMode) {
      return;
    }
    const button: HTMLElement = document.getElementById('wb-button');
    button.onclick = (ev: MouseEvent) => {
      ev.preventDefault();
      callback();
    };
  }

}
