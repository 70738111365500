<div class="cookies-settings">
  <div class="popup-content col-12">
    <div class="cookies-settings__header">
      <div class="row">
        <div class="col-md-3 cookies-settings__header__logo">
          <img alt="logo" src="assets/images/logo.png" />
        </div>
        <div class="col-md-9">
          <h4>Polityka i ustawienia Cookies</h4>
          <button (click)="onClose()" class="btn float-end" title="Zamknij">
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>
    <div class="cookies-settings__body">
      <div class="row">
        <div class="col-md-3">
          <ul>
            <li [ngClass]="{ active: typeOfCookiesSelected === 'NECESSARY' }">
              <a (click)="selectTypesOfCookies('NECESSARY')">
                <span>Cookies Niezbędne</span>
              </a>
            </li>
            <li [ngClass]="{ active: typeOfCookiesSelected === 'UNNECESSARY' }">
              <a (click)="selectTypesOfCookies('UNNECESSARY')">
                <span>Cookies Analityczne</span>
              </a>
            </li>
          </ul>
        </div>
        <div [ngSwitch]="typeOfCookiesSelected" class="col-md-9">
          <div *ngSwitchCase="'NECESSARY'">
            <p><strong>Cookies Niezbędne</strong></p>
            <p>
              oznacza dane informatyczne zapisywane i przechowywane przez przeglądarkę, związane ze świadczeniem przez
              niego usług drogą elektroniczną za pośrednictwem Strony Internetowej, są one wymagane do poprawnego
              działania Strony Internetowej.
            </p>

            <p><strong>Cookies Niezbędne</strong></p>
            <p class="cookies-settings__body--cookies-list">cookies.cookiesAllowed</p>
            <p class="cookies-settings__body--cookies-list">app.token</p>
          </div>
          <div *ngSwitchCase="'UNNECESSARY'">
            <div class="row form-group wb-form">
              <div class="col-12">
                <input [(ngModel)]="unnecessaryCheck" id="cookies-analytics" type="checkbox" />
                <label for="cookies-analytics">
                  <strong>Cookies Analityczne</strong>
                  oznacza Cookies zapisywane przez Stronę Internetową na urządzeniu końcowym przez partnerów
                  Administratora, służących do analizy ruchu na stronie np. Google Analytics.
                </label>
              </div>
            </div>
            <p><strong>Cookies Analityczne</strong></p>
            <p class="cookies-settings__body--cookies-list">_gat_gtag_{{ googleGatGtag }}</p>
            <p *ngIf="isFaceBook" class="cookies-settings__body--cookies-list">Facebook</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cookies-settings__footer">
      <button (click)="onSaveSettingsAndClose()" class="btn transparent-button float-end" title="Zapisz ustawienia">
        Zapisz ustawienia
      </button>
    </div>
  </div>
</div>
