import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { GuardService } from './services/guard.service';

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(
      [
        {
          path: 'auth',
          loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
        },
        {
          path: '',
          canActivate: [GuardService],
          loadChildren: () => import('./pages/core/core.component').then((m) => m.CoreModule),
        },
      ],
      {
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: QuicklinkStrategy,
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
