import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { INominatimResponse } from '../models/nominatim-response.model';

@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultListComponent {

  @Output()
  public readonly locationSelected = new EventEmitter();

  private _resultsOfSearch: INominatimResponse[];

  get resultsOfSearch(): INominatimResponse[] {
    return this._resultsOfSearch;
  }

  @Input()
  set resultsOfSearch(value: INominatimResponse[]) {
    this._resultsOfSearch = value;
  }

  public selectResult(result: INominatimResponse): void {
    this.locationSelected.emit(result);
  }

}

@NgModule({
  declarations: [SearchResultListComponent],
  imports: [
    CommonModule
  ],
  exports: [SearchResultListComponent]
})
export class SearchResultListModule {
}
