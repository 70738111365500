import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Injectable()
export class UtilsService {
  public getAllFileSizes(filesUploader: FileUploader[]): number {
    return filesUploader.reduce(
      (previousFilesSize, currentFileUploader) =>
        previousFilesSize +
        currentFileUploader.queue.reduce(
          (previousValue1, currentValue1) => (previousValue1 + currentValue1.file.size) as number,
          0,
        ),
      0,
    );
  }
}
