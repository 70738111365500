import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';

@Component({
  selector: 'app-phone-help',
  templateUrl: './phone-help.component.html',
  styleUrls: ['./phone-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneHelpComponent {
}

@NgModule({
  declarations: [PhoneHelpComponent],
  imports: [
    CommonModule
  ],
  exports: [PhoneHelpComponent]
})
export class PhoneHelpModule {
}
