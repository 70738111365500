import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListResponseData, IPos, IPosListDto } from '@wienerberger/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IUser } from './models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly _httpClient: HttpClient) {}

  public getMe(): Observable<IUser> {
    return this._httpClient.get<IUser>(`${environment.api}/user/me`);
  }

  public logout(): Observable<any> {
    return this._httpClient.get<any>(`${environment.api}/logout`);
  }

  public getPositions(listDto: IPosListDto): Observable<IListResponseData<IPos>> {
    return this._httpClient
      .get<IListResponseData<IPos>>(`${environment.api}/public/pos`, {
        params: JSON.parse(JSON.stringify(listDto)),
      })
      .pipe(
        map((list) => {
          if (!list) {
            return list;
          }
          list.data.forEach((position: IPos) => {
            const { zip_code, city, street } = position;
            position.complete_address = `${zip_code} ${city} ${street}`;
            position.name = position.name.replace(/&amp;/g, '&');
          });
          return list;
        }),
      );
  }
}
