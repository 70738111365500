import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CookiesState, CookiesStateSaveSettings } from '@wienerberger/states';
import { TypesOfCookies } from '../models/types-of-cookies';

export const GOOGLE_GAT_GTAG = new InjectionToken<string>('google_gat_gtag');
export const IS_FACEBOOK = new InjectionToken<boolean>('is_facebook');

@Component({
  selector: 'app-cookies-settings',
  templateUrl: './cookies-settings.component.html',
  styleUrls: ['./cookies-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesSettingsComponent implements OnInit {

  private _cookiesLocalStorageKey: string;

  get cookiesLocalStorageKey(): string {
    return this._cookiesLocalStorageKey;
  }

  @Input()
  set cookiesLocalStorageKey(value: string) {
    this._cookiesLocalStorageKey = value;
  }

  public unnecessaryCheck = false;
  public typeOfCookiesSelected: TypesOfCookies = TypesOfCookies.NECESSARY;

  constructor(
    @Inject(GOOGLE_GAT_GTAG)
    public readonly googleGatGtag: string,
    @Inject(IS_FACEBOOK)
    public readonly isFaceBook: boolean,
    private readonly _ngbActiveModal: NgbActiveModal,
    private readonly _store: Store
  ) {
  }

  public ngOnInit(): void {
    this._setUnnecessaryCheckboxValue();
  }

  public onSaveSettingsAndClose(): void {
    this._store.dispatch(new CookiesStateSaveSettings(this.unnecessaryCheck, this.cookiesLocalStorageKey));
    this.onClose();
  }

  public onClose(): void {
    this._ngbActiveModal.close(false);
  }

  public selectTypesOfCookies(typesOfCookies: 'NECESSARY' | 'UNNECESSARY'): void {
    this.typeOfCookiesSelected = typesOfCookies as TypesOfCookies;
  }

  private _setUnnecessaryCheckboxValue(): void {
    this.unnecessaryCheck = this._store.selectSnapshot(CookiesState.cookiesAllowed) || false;
  }

}

@NgModule({
  declarations: [CookiesSettingsComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [CookiesSettingsComponent]
})
export class CookiesSettingsModule {
}
