import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxsModule, Select, Store } from '@ngxs/store';
import { IListResponseData, IPos, SpinnerComponentAbstract } from '@wienerberger/data';
import * as L from 'leaflet';
import { Control, MapOptions } from 'leaflet';
import 'leaflet.markercluster';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LeafletMarkerClusterModule } from './leaflet-markercluster/leaflet-markercluster.module';
import { IMapPoint } from './models/map-point.model';
import { INominatimResponse } from './models/nominatim-response.model';
import { SearchResultListModule } from './search-result-list/search-result-list.component';
import { WhereToBuyService } from './services/where-to-buy.service';
import { WhereToBuyState, WhereToBuyStateSearch } from './where-to-buy.state';

@Component({
  selector: 'app-where-to-buy',
  templateUrl: './where-to-buy.component.html',
  styleUrls: ['./where-to-buy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WhereToBuyComponent extends SpinnerComponentAbstract implements OnInit, OnDestroy {
  @Select(WhereToBuyState.loading)
  public readonly loading$: Observable<boolean>;
  @Select(WhereToBuyState.searchResults)
  public readonly searchResults$: Observable<INominatimResponse[]>;

  @Output()
  public readonly confirmPosition = new EventEmitter<IPos>();
  @Output()
  public readonly changeSelectedPromotions = new EventEmitter<{
    porothermChecked: boolean;
    koramicChecked: boolean;
    semmelrockChecked: boolean;
  }>();
  public readonly keyUp = new BehaviorSubject<string>('');
  public readonly layersControlOptions: Control.LayersOptions = { position: 'bottomright' };
  public readonly options: MapOptions = {
    maxZoom: 20,
    zoom: 6,
    center: L.latLng([52.237049, 21.017532]),
  };
  public readonly markerClusterOptions: L.MarkerClusterGroupOptions = {
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: true,
    zoomToBoundsOnClick: true,
    iconCreateFunction: (cluster) => WhereToBuyService.buildGroupMarker(cluster),
  };
  public markerClusterData: L.Marker[] = [];
  public markerClusterGroup: L.MarkerClusterGroup;
  public mapPoint: IMapPoint;
  public porothermChecked = false;
  public koramicChecked = false;
  public semmelrockChecked = false;
  public readonly baseLayers = {
    'Open Street Map':
      window.location.href.indexOf('korzyscimurowane') > -1
        ? L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
            maxZoom: 20,
            attribution:
              '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
          })
        : L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 20,
            attribution: 'Open Street Map',
          }),
  };
  private _map: L.Map;

  constructor(
    private readonly _store: Store,
    private readonly _whereToBuyService: WhereToBuyService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _ngxSpinnerService: NgxSpinnerService,
  ) {
    super(_ngxSpinnerService, 'WHERE_TO_BUY_SPINNER_NAME');
  }

  private _title: TemplateRef<unknown>;

  get title(): TemplateRef<unknown> {
    return this._title;
  }

  @Input()
  set title(value: TemplateRef<unknown>) {
    this._title = value;
  }

  private _visibleCheckboxes: {
    semmelrockVisible: boolean;
    koramicVisible: boolean;
    porothermVisible: boolean;
  } = {
    semmelrockVisible: true,
    koramicVisible: true,
    porothermVisible: true,
  };

  get visibleCheckboxes(): { semmelrockVisible: boolean; koramicVisible: boolean; porothermVisible: boolean } {
    return this._visibleCheckboxes;
  }

  @Input()
  set visibleCheckboxes(value: { semmelrockVisible: boolean; koramicVisible: boolean; porothermVisible: boolean }) {
    this._visibleCheckboxes = value;
  }

  private _positions: IListResponseData<IPos>;

  get positions(): IListResponseData<IPos> {
    return this._positions;
  }

  @Input()
  set positions(value: IListResponseData<IPos>) {
    this._positions = value;
    this.refreshMarkers();
  }

  private _isSelectMode: boolean;

  get isSelectMode(): boolean {
    return this._isSelectMode;
  }

  @Input()
  set isSelectMode(value: boolean) {
    this._isSelectMode = value;
  }

  private _promoName: 'Semmelrock' | 'Porotherm' | 'Koramic' = null;

  get promoName(): 'Semmelrock' | 'Porotherm' | 'Koramic' {
    return this._promoName;
  }

  @Input()
  set promoName(value: 'Semmelrock' | 'Porotherm' | 'Koramic') {
    this._promoName = value;
    this.setProperMarkersForSinglePromo();
  }

  public markerClusterReady(group: L.MarkerClusterGroup): void {
    this.markerClusterGroup = group;
  }

  public onSearch(_: MouseEvent): void {
    this._store.dispatch(new WhereToBuyStateSearch(this.keyUp.getValue(), this.positions));
  }

  public confirmLocation(position: IPos): void {
    if (this.confirmPosition.observers.length > 0) {
      this.confirmPosition.emit(position);
    }
  }

  public onMapReady(map: L.Map): void {
    this._map = map;
    this._map.invalidateSize();
  }

  public onLocationSelected(response: INominatimResponse): void {
    this._updateMapPoint(response);
    this._goToPosition();
    // hide list
    this._store.dispatch(new WhereToBuyStateSearch('', this.positions));
  }

  public onChange(): void {
    this.changeSelectedPromotions.emit({
      porothermChecked: this.porothermChecked,
      koramicChecked: this.koramicChecked,
      semmelrockChecked: this.semmelrockChecked,
    });
  }

  public refreshMarkers(): void {
    if (this.markerClusterGroup) this.markerClusterGroup.clearLayers();
    this._setMarkersOnMap();
    this._setSubscriptions();
  }

  public setProperMarkersForSinglePromo(): void {
    if (this._promoName === 'Semmelrock') {
      this.semmelrockChecked = true;
      this.porothermChecked = false;
      this.koramicChecked = false;
    } else if (this._promoName === 'Porotherm') {
      this.semmelrockChecked = false;
      this.porothermChecked = true;
      this.koramicChecked = false;
    } else if (this._promoName === 'Koramic') {
      this.semmelrockChecked = false;
      this.porothermChecked = false;
      this.koramicChecked = true;
    }
    this.onChange();
  }

  protected getLoadingObservable(): Observable<boolean> {
    return this.loading$;
  }

  private _updateMapPoint({ latitude, longitude, displayName }: INominatimResponse): void {
    this.mapPoint = {
      latitude: latitude,
      longitude: longitude,
      name: displayName || this.mapPoint.name,
    };
  }

  private _goToPosition(): void {
    const coordinates = L.latLng([this.mapPoint.latitude, this.mapPoint.longitude]);
    // this._lastLayer = L.marker(coordinates).setIcon(mapIcon).addTo(this._map);
    this._map.setView(coordinates, 11);
    this._changeDetectorRef.markForCheck();
  }

  private _setSubscriptions(): void {
    this.subscriptionsForDestroy.add(
      this.keyUp.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((searchValue) => {
        this._store.dispatch(new WhereToBuyStateSearch(searchValue, this.positions));
      }),
    );
  }

  private _setMarkersOnMap(): void {
    const markers: L.Marker[] = [];
    const icon = L.divIcon({
      html: `<img alt="marker" src="assets/images/marker.svg">`,
      popupAnchor: [0, -30],
      className: 'wb-marker',
      iconSize: L.point(40, 40),
    });

    this.positions?.data?.forEach((position) => {
      const lat = Number(position.latitude);
      const lng = Number(position.longitude);
      if (!isNaN(lat) && !isNaN(lng)) {
        const marker = L.marker([lat, lng], { icon })
          .bindPopup(this._whereToBuyService.buildMapPopupContent(position, this.isSelectMode))
          .on('popupopen', (_) => {
            this._whereToBuyService.setOnclickOnButtonWhenIsSelectMode(this.isSelectMode, () => {
              this.confirmLocation(position);
            });
          })
          .openPopup();
        markers.push(marker);
      }
    });
    this.markerClusterData = [...markers];
    if (this.markerClusterGroup) {
      this.markerClusterGroup.addLayers(this.markerClusterData);
    }
    this._changeDetectorRef.markForCheck();
  }
}

@NgModule({
  declarations: [WhereToBuyComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([WhereToBuyState]),
    LeafletModule,
    LeafletMarkerClusterModule,
    NgxSpinnerModule,
    SearchResultListModule,
    FormsModule,
    NgOptimizedImage,
  ],
  exports: [WhereToBuyComponent],
})
export class WhereToBuyModule {}
