import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export class Utils {
  public static scrollToError(): void {
    const elementList = document.querySelectorAll('.ng-invalid:not(div):not(form):not(table):not(tr)');
    if (elementList && elementList.length > 0) {
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public static isPasswordErrorExists(abstractControl: AbstractControl): boolean {
    return (
      abstractControl.hasError('hasLower') ||
      abstractControl.hasError('hasLower') ||
      abstractControl.hasError('hasUpper') ||
      abstractControl.hasError('hasNumber') ||
      abstractControl.hasError('hasSpecial') ||
      abstractControl.hasError('minlength')
    );
  }

  public static markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public static getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
    const passwordsMessage =
      'Hasło powinno zawierać co najmniej 8 znaków, w tym małą i dużą literę, znak specjalny i cyfrę.';
    const config = {
      cardNumber: 'Podaj poprawny numer karty.',
      mask: 'Podaj wartość w poprawnym formacie.',
      pesel: 'Podaj poprawny numer pesel.',
      email: 'Podaj prawidłowy adres e-mail.',
      required: 'To pole jest obowiązkowe.',
      maxlength: `Minimalna długość pola to ${validatorValue.requiredLength}.`,
      minlength: `Maksymalna długość pola to ${validatorValue.requiredLength}.`,
      matchOther: `Podane hasła muszą być identyczne.`,
      hasUpper: passwordsMessage,
      hasSpecial: passwordsMessage,
      hasNumber: passwordsMessage,
      hasLower: passwordsMessage,
      ngbDate: 'Podaj poprawną datę.',
    };
    return config[validatorName];
  }

  public static setErrors(errorsObj: any, form: UntypedFormGroup | UntypedFormArray): void {
    if (typeof errorsObj === 'string') {
      return;
    }

    if (Array.isArray(errorsObj)) {
      for (let i = 0; i < errorsObj.length; i++) {
        this.setErrors(errorsObj[i]['children'], form?.controls[i]);
      }
      return;
    }

    for (const key in errorsObj) {
      if (!Object.prototype.hasOwnProperty.call(errorsObj, key)) {
        continue;
      }

      // if has nested values
      if (errorsObj[key]['children'] && form.controls[key]) {
        const formGroup = form.controls[key];
        this.setErrors(errorsObj[key]['children'], formGroup);
        continue;
      }

      if (typeof errorsObj[key] === 'object' && errorsObj[key] !== null && !Array.isArray(errorsObj[key])) {
        const errors = {};
        for (const err in errorsObj[key]) {
          if (Object.prototype.hasOwnProperty.call(errorsObj[key], err)) {
            errors[err] = errorsObj[key][err];
          }
        }
        if (Object.keys(errors).length > 0 && errors['errors'] && form.get(key)) {
          form.get(key)?.setErrors({ backend: { errors: errors['errors'][0] } });
        }
      } else if (form.get(key)) {
        form.get(key)?.setErrors({ backend: [errorsObj[key]] });
      }
    }

    setTimeout(() => {
      Utils.scrollToError();
    }, 250);
  }
}
