import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IListResponseData, IPos } from "@wienerberger/data";
import { Observable, of } from "rxjs";
import { INominatimResponse } from "./models/nominatim-response.model";

import { WhereToBuyService } from "./services/where-to-buy.service";

export class WhereToBuyStateSearch {
  public static readonly type = "[WhereToBuyState] search";

  constructor(
    public readonly searchValue: string,
    public readonly positions: IListResponseData<IPos>
  ) {}
}

export interface WhereToBuyStateStateModel {
  loading: boolean;
  searchResults: INominatimResponse[];
}

@State<WhereToBuyStateStateModel>({
  name: "whereToBuy",
  defaults: {
    loading: false,
    searchResults: null,
  },
})
@Injectable()
export class WhereToBuyState {
  constructor(private readonly _whereToBuyService: WhereToBuyService) {}

  @Selector()
  public static loading({ loading }: WhereToBuyStateStateModel): boolean {
    return loading;
  }

  @Selector()
  public static searchResults({
    searchResults,
  }: WhereToBuyStateStateModel): INominatimResponse[] {
    return searchResults;
  }

  @Action(WhereToBuyStateSearch)
  public search(
    { patchState, getState }: StateContext<WhereToBuyStateStateModel>,
    { searchValue, positions }: WhereToBuyStateSearch
  ): Observable<any> {
    if (searchValue.length > 2) {
      const filterWords = searchValue?.toLowerCase().split(" ");
      const founded: IPos[] = positions?.data?.filter((value) => {
        const { city, street, name, zip_code } = value;
        return filterWords.every((word) => {
          return (
            city.toLowerCase().indexOf(word) !== -1 ||
            street.toLowerCase().indexOf(word) !== -1 ||
            name.toLowerCase().indexOf(word) !== -1 ||
            zip_code.toLowerCase().indexOf(word) !== -1 ||
            !searchValue
          );
        });
      });
      patchState({
        searchResults: founded.map(
          ({ name, complete_address, longitude, latitude }) => ({
            displayName: `${name} - ${complete_address}`,
            latitude: Number(latitude),
            longitude: Number(longitude),
          })
        ),
      });
    } else {
      patchState({ searchResults: [] });
    }
    return of([]);
  }
}
