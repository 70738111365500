<div class="wb-phone-help">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <img alt="phone_icon" src="assets/images/phone_icon.svg" />
        <a class="ms-2 d-flex align-self-center" href="tel:+48 800 919 515">+48 800 919 515</a>
      </div>
    </div>
  </div>
</div>
