import { ModuleWithProviders, NgModule } from '@angular/core';

import { LeafletMarkerClusterDirective } from './leaflet-markercluster.directive';

@NgModule({
  exports: [LeafletMarkerClusterDirective],
  declarations: [LeafletMarkerClusterDirective]
})
export class LeafletMarkerClusterModule {

  public static forRoot(): ModuleWithProviders<LeafletMarkerClusterModule> {
    return { ngModule: LeafletMarkerClusterModule, providers: [] };
  }

}
